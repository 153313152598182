import React, { useEffect, useState } from "react";

export const List = ({
  selectMap,
  setGoToSlide,
  transformY,
  goToSlide,
}: any) => {
  const myTrasform = () => {
    switch (goToSlide) {
      case 0:
        return 36;
        break;
      case 1:
        return 14;
        break;
      case 2:
        return -8;
        break;
      case 3:
        return -30;
        break;
    }
  };
  return (
    <ul
      style={{
        transform: `translateY(${myTrasform()}%)`,
        width: "100px",
        transition: ".3s",
      }}
    >
      <li
        className={`item ${goToSlide === 0 ? "selected" : ""}`}
        onClick={() => {
          setGoToSlide(0);
        }}
      >
        House
      </li>
      <li
        className={`item ${goToSlide === 1 ? "selected" : ""}`}
        onClick={() => {
          setGoToSlide(1);
        }}
      >
        Beach
      </li>
      <li
        className={`item ${goToSlide === 2 ? "selected" : ""}`}
        onClick={() => {
          setGoToSlide(2);
        }}
      >
        Yeti
      </li>
      <li
        className={`item ${goToSlide === 3 ? "selected" : ""}`}
        onClick={() => {
          setGoToSlide(3);
        }}
      >
        ...
      </li>
    </ul>
  );
};
