import React, { useState } from "react";
import background from "../images/faq.png";
import plus from "./../images/faq/plus.svg";
import minus from "../images/faq/minus.svg";

const AccordionItem = ({ showDescription, item, index, onClick }: any) => {
  return (
    <div className="faq__question" key={index} id="FAQ">
      <dt>
        <p
          data-qa="faq__question-button"
          className={`headerH04`}
          style={{
            padding: "24px 0",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={onClick}
        >
          {item.question}
          <img src={showDescription ? minus : plus} />
        </p>
      </dt>
      <dd>
        <p
          id={`faq${index + 1}_desc`}
          data-qa="faq__desc"
          className={`faq__desc bodyText ${showDescription}`}
        >
          {item.answer}
        </p>
      </dd>
    </div>
  );
};

const Accordion = ({ questionsAnswers }: any) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const renderedQuestionsAnswers = questionsAnswers.map(
    (item: any, index: number) => {
      const showDescription = index === activeIndex ? "show-description" : "";
      const fontWeightBold = index === activeIndex ? "font-weight-bold" : "";
      const ariaExpanded = index === activeIndex ? "true" : "false";

      return (
        <React.Fragment key={`faq__${index}`}>
          <AccordionItem
            showDescription={showDescription}
            fontWeightBold={fontWeightBold}
            ariaExpanded={ariaExpanded}
            item={item}
            index={index}
            onClick={() => {
              setActiveIndex(index === activeIndex ? null : index);
            }}
          />
        </React.Fragment>
      );
    }
  );

  return (
    <div className="faq">
      <dl className="faq__list">{renderedQuestionsAnswers}</dl>
    </div>
  );
};

export const Faq = () => {
  const questionsAnswers: { question: string; answer: string }[] = [
    {
      question: "What is the Plurius Project?",
      answer:
        "Plurius is a unique virtual world where players will be able to build, own, and monetize their gaming experiences using $PLRS token. Our goal is to make Plurius a completely decentralized metaverse.",
    },
    {
      question: "Which blockchain is Plurius based on?",
      answer: "Plurius project is based on Cardano blockchain.",
    },
    {
      question: "What is our vision?",
      answer:
        "Plurius virtual world uses blockchain technology and NFTs to empower players and creators. NFTs are an emerging segment in the global game market: virtual tokens for digital scarcity, security and authenticity. Every NFT is distinct and unique, it's indivisible and it's not interchangeable for another. Plurius will become decentralized virtual reality platform powered by the Cardano blockchain. Within the Plurius platform, users will be able to create, experience, and monetize their content and applications.",
    },
    {
      question: "Where can we find more information about the project?",
      answer:
        "You will be able to find more information about our project and plans for the future in the upcoming WhitePaper 1.0 as well as on our Discord channel!",
    },
  ];

  return (
    <section
      className="faq"
      id="FAQ"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="faq__container">
        <div className="faq__col">
          <h1 className="headerH02">
            Your <span className="magentaLighten02">questions</span>
            <br /> answered.
          </h1>
        </div>
        <div className="faq__col">
          <Accordion questionsAnswers={questionsAnswers} />
        </div>
      </div>
    </section>
  );
};
