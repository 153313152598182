import React from "react";
import { Link } from "gatsby";
import ArrowIcon from "./../images/arrowIcon.svg";

interface ButtonGoToProps {
  className?: string;
  label?: string;
  to?:string;
}

export const ButtonGoTo = ({ className, label,to }: ButtonGoToProps) => {
  return (
    <Link to={to || "#"} className={`buttonGoTo ${className}`}>
      {label ?? "Plurius Map"} <img src={ArrowIcon} />{" "}
    </Link>
  );
};

ButtonGoTo.defaultProps = {
  className: "",
};
