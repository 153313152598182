import React from "react";
import oligamy from "../images/logoWhite.svg";

export const Oligamy = () => {
  return (
    <section className="oligamy" id="Oligamy">
      <div className="oligamy__content">
        <img
          alt="oligamy"
          src={oligamy}
          width="30px"
          className="oligamy__content--image"
        />
        <p className="bodyTextSmall">
          Powered by{" "}
          <a href="https://oligamy.com" className="textDecorationUnderline">
            Oligamy Software
          </a>
        </p>
      </div>
    </section>
  );
};
