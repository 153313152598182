import React, {useEffect, useRef, useState} from "react";
import background from "./../images/wideo.png";
import youtube from "./../images/youtubeComingSoon.png";
import video from "./../images/video.webm";
import gif from "./../images/video.gif";

import ReactPlayer from 'react-player/lazy'

const bullets = [
  "Airdrops",
  "Access to exclusive Discord channels",
  "Early sneak peeks of upcoming releases",
  "Opportunities to provide feedback or suggestions on the project’s direction or roadmap",
  "Discounts on future purchases",
  "Priority access to limited edition releases",
  "Access to special events or meetups",
  "Customized or personalized merchandise",
  "Recognition as a top supporter or contributor to the project",
  "VIP customer service and support.",
  "Exclusive giveaways or contests",
  "Early access to new features or functionality",
];

export const Film = () => {




  return (
    <section
      id="Videos"
      className="film"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="film__container">
        <h1 className="headerH02 film__container--header">
          Stay tuned for our cyclic Youtube videos!
        </h1>
        <div className="film__container--films">
          <div className="about__column about__column--film">
            <p className="bodyText">
              Our VIP card offers a range of exclusive perks and benefits to our
              most valued supporters and contributors. With the VIP card, you’ll
              gain access to a variety of special features and privileges that
              are designed to reward your loyalty and enhance your experience
              with our project. Whether you’re an early adopter, a frequent
              buyer, or a dedicated member of our community, the VIP card is
              your passport to a world of exclusive opportunities and benefits.
              So without further ado, here are some of the perks that come with
              being a VIP cardholder...
            </p>
            <img
                style={{ marginTop: 20 }}
                width="250"
                height="250"
                src={gif}/>
          </div>
          <div className="about__column">
            <ul className="bodyText">
              {bullets.map((e) => (
                <li>{e}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
