import React from "react";
import background from "../images/community.png";
import discord from "../images/discord.png";
import { ButtonGoTo } from "./buttonGoTo";
import { Container } from "./container";

export const Community = () => (
  <section
    className="community"
    id="Community"
    style={{ backgroundImage: `url(${background})` }}
  >
    <Container style={{ display: "flex" }}>
      <div className="community__col community__content">
        <h3 className="headerH03 community__content--paragraph">
          <span className="magentaLighten02"> Join now,</span> be part of our
          amazing community and stay tuned for any upcoming news!
        </h3>
        <p className="bodyText community__content--text">
          Soon, we will be opening our Discord and Telegram channels. Please
          watch out for people trying to impersonate us and join only using our
          official links found on our website/twitter. By joining our media, you
          will be up to date with our latest news and you'll be able to talk
          with our fellow Plurians!
        </p>
        <ButtonGoTo
          className="community__content--button"
          label="Join DIscord"
        />
      </div>
      <div className="community__col">
        <img src={discord} className="community__col__image" />
      </div>
    </Container>
  </section>
);
