import React, { useEffect, useState } from "react";
import background from "../images/roadmap.png";
import Stepper from "@mui/material/Stepper";
import { Step, StepLabel, MobileStepper } from "@mui/material";
import right from "./../../src/images/rightRed.svg";
import left from "./../../src/images/leftRed.svg";
import { useWidth } from "../hooks/useWitdth";
import { useSwipeable } from "react-swipeable";

const steps = [
  {
    time: "Q1 2022",
    label: "Plurius Vision",
    description:
      "Team building, project research and detailed planning, exploring smart contracts on Cardano, project structure decided",
  },
  {
    time: "Q1 2022",
    label: "Design Starts",
    description:
      "Designing our V1 Plurius map, 3D model of the map, development starts, first innovative 2D map ready to explore via browser, first prototypes in Unreal Engine",
  },
  {
    time: "Q1 2023",
    label: "Plurius Minter",
    description: (
      <>
        Building custom, automated Plurius NFT minter to ensure maximum
        stability and performance
      </>
    ),
  },
  {
    time: <>Q2 2022</>,
    label: "Website 1.0 Release",
    description: (
      <>
        Visit and explore our brand new Website 1.0 containing Plurius map
        explorer
      </>
    ),
  },
  {
    time: "Q2 2023",
    label: "Socials",
    description: <>Discord, Twitter and Telegram launches! </>,
  },
  {
    time: "Q2 2023",
    label: "3D Tools",
    description: (
      <>
        Development of 3D tools for Plurius that can be used by any community
        member begins
      </>
    ),
  },
  {
    time: "Q1 2023",
    label: "Land Sale One!",
    description: <>District One Plurius LAND sale starts!</>,
  },
  {
    time: "Q2 2023",
    label: "WhitePaper 1.0",
    description: <>Design Your Land.</>,
  },
  {
    time: "Q2 2023",
    label: "We keep on building!",
    description:
      "This roadmap will be continuously updated as we move on with out roadmap points! Keep your eyes open for more detailed future milestones ahead of us!",
  },
];

export const Roadmap = () => {
  const [translateX, setTranslateX] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const { isDesktop, width } = useWidth();

  useEffect(() => {
    setActiveStep(window.innerWidth > 992 ? 2 : 1);
    setTranslateX(window.innerWidth > 992 ? 0 : 0);
  }, []);

  const onSwipedLeft = () => {
    if (activeStep < (isDesktop ? 8 : 9)) {
      setActiveStep(activeStep + 1);
      setTranslateX(isDesktop ? translateX - 11.1 : translateX - width!);
    }
  };

  const onSwipedRight = () => {
    if (activeStep > (isDesktop ? 2 : 1)) {
      setActiveStep(activeStep - 1);
      setTranslateX(isDesktop ? translateX + 11.1 : translateX + width!);
    }
  };

  const handlers = useSwipeable({
    onSwipedRight,
    onSwipedLeft,
  });

  return (
    <section
      {...handlers}
      id="Roadmap"
      className="roadmap"
      style={{ backgroundImage: `url(${background})` }}
    >
      <h1 className="headerH01 roadmap__header">DAO Roadmap</h1>
      <div style={{ position: "relative", width: "100%" }}>
        <div
          className="roadmap__stepper__container"
          style={{
            transform: isDesktop
              ? `translateX(${translateX}%)`
              : `translateX(${translateX}px)`,
            width: isDesktop ? "300vw" : `${steps.length * 100}vw`,
          }}
        >
          <Stepper activeStep={2} alternativeLabel>
            {steps.map(({ label, time, description }, index) => (
              <Step
                key={index}
                className={`roadmap__step ${
                  index + 1 === activeStep ? "active" : ""
                }`}
              >
                <p className="roadmap__step--header headerH06">{label}</p>
                <p className="roadmap__step--subtitle">{time}</p>
                <StepLabel />
                <p className="roadmap__step--paragraph">{description}</p>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
      <div className="roadmap__arrows">
        <img
          style={{ opacity: activeStep === (isDesktop ? 2 : 1) ? ".5" : "1" }}
          src={left}
          onClick={onSwipedRight}
        />
        <img
          style={{ opacity: activeStep === (isDesktop ? 8 : 9) ? ".5" : "1" }}
          src={right}
          onClick={onSwipedLeft}
        />
      </div>
    </section>
  );
};
