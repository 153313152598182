import React from "react";
import background from "../images/teamBackground.png";
import teamSmall from "../images/teamSmall.svg";
import teamLarge from "../images/teamLarge.svg";

import grid from "../images/grid.png";
import gridMobile from "../images/gridMobile.png";
import { Avatar } from "./avatar";
import { useWidth } from "../hooks/useWitdth";

import Artur from "./../images/avatars/artur.png";
import Jakob from "./../images/avatars/jacob.png";
import Pizzer from "./../images/avatars/Pizzermn.png";
import Elisa from "./../images/avatars/eliza.png";
import Andrzej from "./../images/avatars/andrzej.png";
import Masza from "./../images/avatars/Masza.png";
import Alosza from "./../images/avatars/alosza.png";
import Traveller from "./../images/avatars/traveller.png";
import Vasaty from "./../images/avatars/wasaty.png";
import Random from "./../images/avatars/random.png";
import Random2 from "./../images/avatars/random2.png";
import Random3 from "./../images/avatars/random3.png";
import Vi from "./../images/avatars/vi.png";

const desktopAvatars = [
  {
    src: Artur,
    nick: "Arturito",
    left: "5%",
    top: "15%",
    text: "Team Lead „Make the impossible possible again”. Favourite CNFT: Spacebudz",
  },
  {
    src: Jakob,
    nick: "Jacob",
    left: "50%",
    top: "15%",
    text: "Lead Marketing and communication Management.  Responsible for communication between Plurius staff and our amazing community. Crypto enthusiast and investor since 2016. Favorite CNFT: SpaceBudz",
  },
  {
    src: Pizzer,
    nick: "Casper",
    left: "35%",
    top: "20%",
    text: "Web designer and front-end developer with particular interest in UI and UX design. From Pizzerman to new Metaverse Developer. Favorite CNFT: Chilled Kongs\n",
  },
  {
    src: Vi,
    nick: "Syx",
    left: "21%",
    top: "31%",
    text: "Backend Developer, love traveling, love journey, making the new journeys for everyone in Plurius. Favorite CNFT: PXLZ",
  },
  {
    src: Andrzej,
    nick: "Andrew",
    left: "8%",
    top: "46%",
    text: "Just Andrew, nothing more, nothing less - just  IT God.  Favourite CNFT: Spacebudz\n",
  },
  {
    src: Masza,
    nick: "MJ",
    left: "15%",
    top: "60%",
    text: "Designer, PixelArt Guru, our drafter magician. Her biggest passion is landscape art, but she loves all different types of Art in general. Favorite CNFT: Yummi cuz they cute",
  },
  {
    src: Elisa,
    nick: "Elisa",
    right: "39%",
    top: "48%",
    text: "Designer, PixelPerfect our Web-God. Love everything that starts with word „Design”. Favorite CNFT: All of them",
  },
  {
    src: Traveller,
    nick: "Adal",
    right: "28%",
    top: "7%",
    text: "3D Rockstar Artist with a love for games and everything that is 3D. Favorite CNFT: GOAT Tribe\n",
  },
  {
    src: Vasaty,
    nick: "Martin",
    left: "38%",
    top: "60%",
    inverted: true,
    text: "3D Artist, Animation in Plurius. Making everything looking Crisp! Favourite CNFT: Every 3D project",
  },
  {
    src: Alosza,
    nick: "Olly",
    inverted: true,
    right: "15%",
    top: "35%",
    text: "Head Of Development, 15 years of experience in IT, Cardano and Doge Coin lover. Technology exec and business growth specialist. Favourite CNFT: Clay Nation\n",
  },
  {
    src: Random,
    inverted: true,
    nick: "Matt",
    right: "10%",
    top: "5%",
    text: "Our office puppy and puppet",
  },
  {
    src: Random2,
    inverted: true,
    nick: "Peter",
    right: "5%",
    bottom: "15%",
    text: "Managing Discord and twitter, community helper. 2 Years of experience in managing discord channels. Favorite CNFT: Cardano Bits",
  },
  {
    src: Random3,
    inverted: true,
    nick: "Rob",
    right: "25%",
    bottom: "15%",
    text: "Product Marketing, crypto freak with 7 years of experience in Crypto space. Favourite CNFT: Chilled Kongs",
  },
];

const mobileAvatars = [
  {
    src: Artur,
    nick: "Arturito",
    right: "20%",
    top: "10.5%",
    text: "Team Lead „Make the impossible possible again”. Favourite CNFT: Spacebudz",
  },
  {
    src: Jakob,
    nick: "Jacob",
    left: "6%",
    top: "5%",
    text: "Lead Marketing and communication Management.  Responsible for communication between Plurius staff and our amazing community. Crypto enthusiast and investor since 2016. Favorite CNFT: SpaceBudz",
  },
  {
    src: Pizzer,
    nick: "Casper",
    left: "7%",
    top: "23%",
    text: "Web designer and front-end developer with particular interest in UI and UX design. From Pizzerman to new Metaverse Developer. Favorite CNFT: Chilled Kongs\n",
  },
  {
    src: Vi,
    nick: "Syx",
    left: "27%",
    top: "35%",
    text: "Backend Developer, love traveling, love journey, making the new journeys for everyone in Plurius. Favorite CNFT: PXLZ",
  },
  {
    src: Andrzej,
    nick: "Andrew",
    right: "4%",
    top: "23.5%",
    text: "Just Andrew, nothing more, nothing less - just  IT God.  Favourite CNFT: Spacebudz\n",
  },
  {
    src: Masza,
    nick: "MJ",
    left: "65%",
    top: "40.5%",
    text: "Designer, PixelArt Guru, our drafter magician. Her biggest passion is landscape art, but she loves all different types of Art in general. Favorite CNFT: Yummi cuz they cute",
  },
  {
    src: Elisa,
    nick: "Elisa",
    left: "4%",
    top: "64.5%",
    text: "Designer, PixelPerfect our Web-God. Love everything that starts with word „Design”. Favorite CNFT: All of them",
  },
  {
    src: Traveller,
    nick: "Adal",
    left: "45%",
    top: "67%",
    text: "3D Rockstar Artist with a love for games and everything that is 3D. Favorite CNFT: GOAT Tribe\n",
  },
  {
    src: Vasaty,
    nick: "Martin",
    left: "6%",
    top: "50%",
    text: "3D Artist, Animation in Plurius. Making everything looking Crisp! Favourite CNFT: Every 3D project\n",
  },
  {
    src: Alosza,
    nick: "Olly",
    left: "33.9%",
    top: "86%",
    text: "Head Of Development, 15 years of experience in IT, Cardano and Doge Coin lover. Technology exec and business growth specialist. Favourite CNFT: Clay Nation\n",
  },
  {
    src: Random,
    nick: "Matt",
    left: "65.9%",
    top: "81.5%",
    text: "Our office puppy and puppet",
  },
  {
    src: Random2,
    nick: "Peter",
    left: "4%",
    top: "79.5%",
    text: "Managing Discord and twitter, community helper. 2 Years of experience in managing discord channels. Favorite CNFT: Cardano Bits",
  },
  {
    src: Random3,
    nick: "Rob",
    left: "55%",
    top: "53.5%",
    text: "Product Marketing, crypto freak with 7 years of experience in Crypto space. Favourite CNFT: Chilled Kongs",
  },
];

export const Team = () => {
  const { isDesktop } = useWidth();
  return (
    <section
      className="team"
      style={{ backgroundImage: `url(${background})` }}
      id="Team"
    >
      <div className="team__header">
        <img
          src={isDesktop ? teamLarge : teamSmall}
          className="team__header__image"
          alt="team"
        />
        <div className="bodyText">
          <div className="bodyText__content">
            Plurius staff are mainly based in Europe but we have team members in
            all over the world. Some work from our offices or studios. Some work
            remotely from wherever they may be. We are constantly looking for
            new talents too - send us an email if you think you would fit our
            team! Below, you can find more info about us!
          </div>
        </div>
      </div>
      <div
        className="team__grid"
        style={{ backgroundImage: `url(${isDesktop ? grid : gridMobile})` }}
      >
        {isDesktop ? (
          <>
            {desktopAvatars.map((props, index) => (
              <Avatar delay={25 * (index + 1)} {...props} />
            ))}
          </>
        ) : (
          <>
            {mobileAvatars.map((props, index) => (
              <Avatar delay={25 * (index + 1)} {...props} />
            ))}
          </>
        )}
      </div>
    </section>
  );
};
