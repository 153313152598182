import React, { useEffect } from "react";
import * as Components from "./../components";

const IndexPage = () => {
  return (
    <>
      <Components.Seo title="Plurius" lang="pl" />
      <Components.Header />
      <Components.Film />
      <Components.About />
      <Components.Map />
      <Components.Team />
      <Components.Community />
      <Components.Roadmap />
      <Components.Faq />
      <Components.Footer />
      <Components.Oligamy />
    </>
  );
};

export default IndexPage;
