import React, { memo, useEffect, useRef, useState } from "react";
import background from "../images/mapBackground.png";

import { ButtonGoTo } from "./buttonGoTo";
import right from "./../images/right.svg";
import left from "./../images/left.svg";
import frame from "./../images/scrollFrame.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useWidth } from "../hooks/useWitdth";
import { Container } from "./container";
import { config } from "react-spring";
import { List } from "./list";
import loadable from "@loadable/component";
import { yeti, house, plaza, word } from "./images";
// @ts-ignore
const CarouselDesktop = loadable(() => import("react-spring-3d-carousel"));

export const Map = () => {
  const { isDesktop } = useWidth();
  const [goToSlide, setGoToSlide] = useState(0);
  const [selectMap, setSelectMap] = useState("Cosmos");
  const [transformY, setTransformY] = useState(-25);

  const goRight = () => {
    if (goToSlide + 1 === 4) {
      setGoToSlide(0);
    } else {
      setGoToSlide(goToSlide + 1);
    }
  };

  const goLeft = (isTrigger?: boolean) => {
    if (goToSlide === 0) {
      setGoToSlide(3);
    } else {
      setGoToSlide(goToSlide - 1);
    }
  };

  const slides = [
    {
      key: 4,
      content: (
        <img
          style={{ width: "500px" }}
          src={`data:image/jpeg;base64,${house}`}
          alt="1"
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          style={{ width: "500px" }}
          src={`data:image/jpeg;base64,${plaza}`}
          alt="1"
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          style={{ width: "500px" }}
          src={`data:image/jpeg;base64,${yeti}`}
          alt="1"
        />
      ),
    },
    {
      key: 1,
      content: (
        <img
          style={{ width: "500px" }}
          src={`data:image/jpeg;base64,${word}`}
          alt="1"
        />
      ),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setGoToSlide(index) };
  });

  const content = (
    <>
      <div className="map__column">
        <h2 className="headerH02 map__column__header">
          Explore our world <br />
          map with an
          <br />
          <span className="magentaLighten02"> innovative 3D tool </span>
          <br />
          coming soon.
        </h2>
        <ButtonGoTo className="mt-64 mb-64 map__column_button" />
      </div>
      {isDesktop ? (
        <div className="map__column">
          <div className="map__slider">
            <div className="map__slider__image">
              <React.Fragment>
                <CarouselDesktop
                  slides={slides}
                  goToSlide={goToSlide}
                  offsetRadius={1}
                  showNavigation={true}
                  animationConfig={config.gentle}
                />
              </React.Fragment>
            </div>
            <div
              style={{ position: "relative", zIndex: 999, cursor: "pointer" }}
            >
              <img src={left} onClick={() => goLeft(true)} />
              <img src={right} className="rightArrow" onClick={goRight} />
            </div>
          </div>
          <div className="map__scroll">
            <img src={frame} />
            <List
              goToSlide={goToSlide}
              setGoToSlide={setGoToSlide}
              selectMap={selectMap}
              transformY={transformY}
            />
          </div>
          <div className="map__mobileSlider"></div>
        </div>
      ) : (
        <Carousel
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={30}
          emulateTouch={true}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          infiniteLoop={true}
        >
          <div>
            <img src={`data:image/jpeg;base64,${house}`} />
          </div>
          <div>
            <img src={`data:image/jpeg;base64,${plaza}`} />
          </div>
          <div>
            <img src={`data:image/jpeg;base64,${yeti}`} />
          </div>
          <div>
            <img src={`data:image/jpeg;base64,${word}`} />
          </div>
        </Carousel>
      )}
    </>
  );
  return (
    <section
      style={{ backgroundImage: `url(${background})` }}
      className="map"
      id="Map"
    >
      {isDesktop ? (
        <Container style={{ display: "flex", padding: "4rem" }}>
          {content}
        </Container>
      ) : (
        <>{content}</>
      )}
    </section>
  );
};
