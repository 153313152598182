import React from "react";
import footer from "../images/footer.png";
import plurius from "../images/logo.webp";
import discordIcon from "../images/discordIcon.svg";
import twitterIcon from "../images/twitterIcon.svg";
import { useWidth } from "../hooks/useWitdth";
import { Container } from "./container";
const globalLinks = [
  "Videos",
  "About",
  "Map",
  "Team",
  "Community",
  "Roadmap",
  "FAQ",
];

export const Footer = () => {
  const { isDesktop } = useWidth();
  return (
    <>
      <footer className="footer">
        <Container
          style={{ display: "flex", flexDirection: isDesktop ? "" : "column" }}
        >
          <div className="footer__col">
            <img src={plurius} className="footer__col--logo" />
            <p className="bodyTextSmall">
              Plurius virtual world uses blockchain technology and NFTs to
              empower players and creators. NFTs are an emerging segment in the
              global game market: virtual tokens for digital scarcity, security
              and authenticity. Each NFT is distinct or unique, it is
              indivisible and it is not interchangeable for another. Plurius
              will become decentralized virtual reality platform powered by the
              Cardano blockchain. Within the Plurius platform, users will be
              able to create, experience, and monetize their content and
              applications.
            </p>
          </div>

          {isDesktop ? (
            <>
              <div className="footer__col">
                <div className="footer__col__content">
                  <h6 className="headerH06 footer__col--header">Global</h6>
                  {globalLinks.map((link) => (
                    <a
                      href={`#${link}`}
                      className="bodyText footer__col--link"
                      key={`Global__${link}`}
                    >
                      {link}
                    </a>
                  ))}
                  <a
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bodyText footer__col--link"
                    onClick={() =>
                      window.open(
                        `${window.location.origin}${window.location.pathname}dummy.pdf`,
                        "_blank"
                      )
                    }
                  >
                    Whitepaper
                  </a>
                </div>
              </div>
              <div className="footer__col">
                <div className="footer__col__content">
                  <h6 className="headerH06 footer__col--header">Follow Us</h6>
                  <div className="footer__col--iconContainer ">
                    <img className="footer__col--icon" src={discordIcon} />
                    <img className="footer__col--icon" src={twitterIcon} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="footer__mobileCol">
              <div className="footer__col">
                <h6 className="headerH06 footer__col--header">Global</h6>
                {globalLinks.map((link) => (
                  <a
                    href={`#${link}`}
                    className="bodyText footer__col--link"
                    key={`Global__${link}`}
                  >
                    {link}
                  </a>
                ))}
                <a
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bodyText footer__col--link"
                  onClick={() =>
                    window.open(
                      `${window.location.origin}${window.location.pathname}dummy.pdf`,
                      "_blank"
                    )
                  }
                >
                  Whitepaper
                </a>
              </div>
              <div className="footer__col">
                <h6 className="headerH06 footer__col--header">Follow Us</h6>
                <div className="footer__col--iconContainer ">
                  <img className="footer__col--icon" src={discordIcon} />
                  <img className="footer__col--icon" src={twitterIcon} />
                </div>
              </div>
            </div>
          )}
        </Container>
      </footer>
    </>
  );
};
