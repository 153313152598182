import React, { useState } from "react";
import avatar from "./../images/avatar.png";
import close from "./../images/close.svg";
import Artur from "./../images/avatars/artur.png";

interface AvatarProps {
  nick?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  children?: React.ReactNode;
  delay?: number;
  text?: string;
  inverted?: boolean;
  isStrap?: boolean;
  src?: string;
}

export const Avatar = ({
  isStrap,
  delay,
  nick,
  left,
  right,
  top,
  bottom,
  children,
  text,
  inverted,
  src,
}: AvatarProps) => {
  const style = {
    bottom,
    left,
    right,
    top,
  };

  const [open, setOpen] = useState(false);

  const animProperties = {
    ["data-sal"]: delay ? "slide-up" : "",
    ["data-sal-delay"]: delay,
    ["data-sal-easing"]: "ease",
  };

  return (
    <div
      //@ts-expect-error
      dataName={nick}
      style={{ ...style, zIndex: open ? "999" : "200" }}
      className={`avatar ${nick && `withName`}`}
      onMouseEnter={() => text && setOpen(true)}
      onMouseLeave={() => text && setOpen(false)}
      {...animProperties}
    >
      {isStrap && (
        <>
          <div className="avatar__strap avatar__strap01" />
          <div className="avatar__strap avatar__strap02" />
          <div className="avatar__strap avatar__strap03" />
        </>
      )}

      <div
        className={`avatar__tooltip ${inverted ? "inverted" : ""}`}
        onClick={() => setOpen(!open)}
        style={{ display: open ? "block" : "none" }}
      >
        <h1 className="avatar__tooltip--header">
          <p>{nick}</p>
        </h1>
        <p className="avatar__tooltip--paragraph">{text}</p>
      </div>
      <img
        src={src ? src : avatar}
        className="avatar__image"
        style={{ width: "100px", height: "100px" }}
      />
      {nick && <p className="avatar__label bodyTextSmall">{nick}</p>}
      {children}
    </div>
  );
};
