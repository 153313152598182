import React from "react";
import background from "../images/aboutBackground.png";
import net from "../images/net.svg";
import { Avatar } from "./avatar";
import { Button } from "./button";
import circle from "../../src/static/image/gradient.png";
import { useWidth } from "../hooks/useWitdth";
export const About = () => {
  const { isMobile } = useWidth();
  return (
    <section
      style={{ backgroundImage: `url(${background})` }}
      className="about"
      id="About"
    >
      <div className="about__column">
        {isMobile && <div className="avatar__strap avatar__strap04" />}
        <button
          className="btnTooltip"
          style={{ left: "40%", width: "415px", bottom: "72%" }}
        >
          Your Plurius journey starts now.
        </button>
        <button className="btnTooltip" style={{ left: "60%", bottom: "45%" }}>
          Design Your Land
        </button>
        <Avatar isStrap={true} children={<Button label="Design Your Land" />} />
      </div>
      <div className="about__column">
        <h3 className="headerH03 about__column__header">
          Explore, build, gather, test the limits of your imagination.
        </h3>
        <p className="bodyText">
          Plurius is a decentralized virtual reality platform powered by the
          Cardano blockchain. Within the Plurius platform, users will be able to
          create, experience, and monetize their content and applications.
        </p>
      </div>
      <img src={net} className="about__net" />
      <img src={circle} className="about__circle" />
    </section>
  );
};
