import React from "react";
import logo from "./../images/logoHeader.png";
import { ButtonGoTo } from "./buttonGoTo";
import backgroundImage from "./../images/backgroundTOP.png";
const Navbar = loadable(() => import("./navbar")); //
import backgroundBottom from "./../images/backgroundBottom.png";
import loadable from "@loadable/component";

export const Header = () => {
  const pluriusMapExplorerLink = 'https://plurius-map-git-poc-strapi-tiles-oligamy.vercel.app'
  return (
    <header
      id="Header"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className="header"
    >
      <div
        className="cover"
        style={{ backgroundImage: `url(${backgroundBottom})` }}
      />
      <Navbar />
      <img src={logo} alt="logo" className="header__logo" />
      <h1 className="headerH01 header__header">
        District One sale coming soon!
      </h1>
      <ButtonGoTo to={pluriusMapExplorerLink} label="Plurius Map Explorer" className="header__button" />
    </header>
  );
};
